<template>
  <b-field label="Preferred currency for new orders">
    <b-select
      v-model="selected"
      :loading="processing"
      :disabled="processing"
      placeholder="Select your preferred currency"
      expanded
      @input="updateCurrency"
    >
      <option v-for="(c, key) in currencies" :key="key" :value="key">
        {{ c.label }} ({{ c.prefix || c.suffix }})
      </option>
    </b-select>
  </b-field>
</template>

<script>
import currencies from "@src/data/currencies.json";
export default {
  name: "AccountCurrency",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      selected: "",
      currencies
    };
  },
  computed: {
    currency() {
      return this.$store.getters["user/currency"](this.userId);
    }
  },
  watch: {
    currency() {
      this.selectCurrency();
    }
  },
  created() {
    this.selectCurrency();
  },
  methods: {
    selectCurrency() {
      this.selected = this.$_.clone(this.currency);
    },
    updateCurrency() {
      if (this.selected === this.currency) return;
      this.processing = true;
      this.$store
        .dispatch("user/updateProfile", {
          userId: this.userId,
          payload: {
            currency: this.selected
          }
        })
        .then(() => {
          this.$toast.open({
            message: "Currency updated",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(error => {
          this.selectCurrency();
          this.$toast.open({
            message: error.message,
            type: "is-danger",
            position: "is-bottom",
            queue: false
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
